import React from 'react';
import {
    VerticalTimeline, 
    VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import SchoolIcon from "@material-ui/icons/School";
import WorkIcon from "@material-ui/icons/Work";
import "../styles/Experience.css";

function Experience() {
  return (
    <div className="experience">
        <VerticalTimeline lineColor="#3e497a">
            <VerticalTimelineElement 
                className="vertical-timeline-element--education" 
                date="2015 - 2019"
                iconStyle={{background: "#3e497a", color: "#fff"}}
                icon={<SchoolIcon />}
            >
                <h3 className="vertical-timeline-element-title">
                    Terry Sanford High School, Fayetteville, NC 
                </h3>
                <p> High School Diploma, Salutatorian</p>

            </VerticalTimelineElement>


            <VerticalTimelineElement 
                className="vertical-timeline-element--education" 
                date="August 2019"
                iconStyle={{background: "#3e497a", color: "#fff"}}
                icon={<SchoolIcon />}
            >
                <h3 className="vertical-timeline-element-title">
                    Enrolled at North Carolina State University
                </h3>
                <p> Studying Computer Science</p>

            </VerticalTimelineElement>

            <VerticalTimelineElement 
                className="vertical-timeline-element--education" 
                date="June - August 2022"
                iconStyle={{background: "#e9d35b", color: "#fff"}}
                icon={<WorkIcon/>}
            >
                <h3 className="vertical-timeline-element-title">
                    Software Engineer Intern - Abrigo
                    <p>Raleigh, NC</p>
                </h3>

            </VerticalTimelineElement>

            <VerticalTimelineElement 
                className="vertical-timeline-element--education" 
                date="August - December 2022"
                iconStyle={{background: "#e9d35b", color: "#fff"}}
                icon={<WorkIcon/>}
            >
                <h3 className="vertical-timeline-element-title">
                    Embedded Software Engineer - ASSIST Center
                    <p>Raleigh, NC</p>
                </h3>

            </VerticalTimelineElement>

            <VerticalTimelineElement 
                className="vertical-timeline-element--education" 
                date="May 2023"
                iconStyle={{background: "#3e497a", color: "#fff"}}
                icon={<SchoolIcon />}
            >
                <h3 className="vertical-timeline-element-title">
                    Completed Bachelor's at North Carolina State University
                </h3>
                <p> Summa Cum Laude </p>

            </VerticalTimelineElement>

            <VerticalTimelineElement 
                className="vertical-timeline-element--education" 
                date="June - August 2023"
                iconStyle={{background: "#e9d35b", color: "#fff"}}
                icon={<WorkIcon/>}
            >
                <h3 className="vertical-timeline-element-title">
                    Software Engineer Intern - Fidelity Investments
                    <p>Raleigh, NC</p>
                </h3>

            </VerticalTimelineElement>


            <VerticalTimelineElement 
                className="vertical-timeline-element--education" 
                date="August 2023"
                iconStyle={{background: "#3e497a", color: "#fff"}}
                icon={<SchoolIcon />}
            >
                <h3 className="vertical-timeline-element-title">
                    Started Masters Program at North Carolina State University
                </h3>
                <p> Computer Science </p>

            </VerticalTimelineElement>
        </VerticalTimeline>
    </div>
  );
}

export default Experience;