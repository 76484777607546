import './App.css';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom'
import Home from "./pages/Home"
import AboutMe from "./pages/AboutMe"
import Experience from "./pages/Experience"
import Photography from "./pages/Photography"
import Navbar from './components/Navbar';

function App() {
  return (
    <div className="App">
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/aboutMe" element={<AboutMe />} />
          <Route path="/experience" element={<Experience />} />
          <Route path="/photography" element={<Photography />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
