import React from 'react'
import '../styles/AboutMe.css'; // make sure to create this CSS file

function AboutMe() {
  return (
    <div className="aboutMeContainer">
      <div className="aboutMeContent">
        <p>Hey! My name is Dev, and I'm a first year Computer Science Masters Student at NC State University. 
          I did my undergrad at NC State as well. I was born and raised in North Carolina, and I'm a big fan of the Carolina Panthers and the Charlotte Hornets.
          I have a broad set of interests including artifical intelligence, cloud computing, full stack web development, and more.
          If you have any questions, feel free to email me at devsashidhar@gmail.com; I would love to chat. </p>
      </div>
    </div>
  )
}

export default AboutMe;
