import React, { useEffect, useState } from "react";
import { Link, useLocation } from 'react-router-dom';
import "../styles/Navbar.css";
import ReorderIcon from '@material-ui/icons/Reorder';
import HomeIcon from '@material-ui/icons/Home';

function Navbar() {
    const [expandNavbar, setExpandNavBar] = useState(false);
    const location = useLocation();

    useEffect(() => {
        setExpandNavBar(false);
    }, [location]);

    return (
        <div className="navbar" id={expandNavbar ? "open" : "close"}>
            <div className="toggleButton"> 
                <button onClick={() => {setExpandNavBar((prev) => !prev);}}>
                    <ReorderIcon />
                </button>
            </div>

            {/* Conditionally render the Home button */}
            {location.pathname !== '/' && (
                <div className="homeButton">
                    <Link to="/">
                        <HomeIcon />
                    </Link>
                </div>
            )}

            <div className="links">
                <Link to="/aboutMe"> About Me </Link>
                <a target="_blank" rel="noopener noreferrer" href="https://drive.google.com/file/d/1cJhroDXzZX0p_lquD7SzUWzps8usOST3/view?usp=sharing">Resume</a>
                <Link to="/experience"> Experience </Link>
                <Link to="/photography"> Photography </Link>
            </div>
        </div>
    );
}

export default Navbar;
