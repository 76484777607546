import React from 'react';
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import EmailIcon from '@material-ui/icons/Email';
import "../styles/Home.css";
import Typewriter from 'typewriter-effect';

function Home() {
    const handleEmailClick = () => {
        // You can customize the email address here
        const email = 'devsashidhar@gmail.com';
        window.location.href = `mailto:${email}`;
    };

    const handleLinkedInClick = () => {
        // You can customize the LinkedIn profile URL here
        const linkedInUrl = 'https://www.linkedin.com/in/devsashidhar';
        window.open(linkedInUrl, '_blank');
    };

    return (
        <div className="home">
            <div className="about">
                <h2> Hi my name is Dev</h2>
                <div className="prompt">
                    <span></span>
                    <span style={{ display: 'inline', whiteSpace: 'nowrap' }}>
                        <Typewriter
                            options={{
                                strings: [
                                    'Welcome to my website',
                                    'I am a team player',
                                    'I am always learning',
                                    'I am a software <b>dev</b>eloper'
                                ],
                                autoStart: true,
                                loop: false, // Changed loop to false
                                deleteSpeed: 50,
                                delay: 75
                            }}
                        />
                    </span>
                    <div>
                        <LinkedInIcon 
                            onClick={handleLinkedInClick} 
                            style={{ cursor: 'pointer' }}
                        />
                        {/* Wrap the email icon in an anchor tag */}
                        <a href="mailto:devsashidhar@gmail.com" onClick={handleEmailClick}>
                            <EmailIcon />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home;
