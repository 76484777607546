import React from "react";
import "../styles/Photography.css"; // Update the CSS file name if necessary

function Photography() {
    // Example images, replace with your actual image paths
    const images = [
        "https://dl.dropboxusercontent.com/scl/fi/e9uymp5613hz0ovnxy2hc/dji-fly-20230509-175132-25-1683765137583-photo-optimized.JPG?rlkey=oy5yl9iot6mumpbpklzbfb8xu&raw=1",
        "https://dl.dropboxusercontent.com/scl/fi/wxit6kj9vjyl9o2ln28zo/dji-fly-20230509-184606-27-1683765116862-photo-optimized.JPG?rlkey=ccivb1eh14niv5mth6evvecyp&raw=1",
        "https://dl.dropboxusercontent.com/scl/fi/rylyy3y0m1wqlggz1t5x2/IMG-6618.jpg?rlkey=i2cgofehw7mtzlo5y8aq0uufp&raw=1",
        "https://dl.dropboxusercontent.com/scl/fi/4vbxiqz6gki4e0fcm5ajs/IMG-7359.jpg?rlkey=7mjn3gsknapgyjnbfm7aurfx1&raw=1",
        "https://dl.dropboxusercontent.com/scl/fi/zr0hn8c4xlpwbsjhe0230/IMG_4141.JPG?rlkey=8u2zonhpxm2lf6iil6tf8cqbm&raw=1",
        "https://dl.dropboxusercontent.com/scl/fi/r9h4ghunmnla07oxjcfx1/IMG_2675.JPG?rlkey=ug4u6kktz6lazo8ywwuaa4v5c&raw=1",
        "https://dl.dropboxusercontent.com/scl/fi/vqe1462s75im493tswjsc/IMG-7580.jpg?rlkey=n7i8pnm9luylhlgdkspx3btp5&raw=1",
        "https://dl.dropboxusercontent.com/scl/fi/qzwjsbfnjuph6hcogpd0f/IMG_1120.JPG?rlkey=33uu4qkjwxvahvlfycww92mtj&raw=1",
        "https://dl.dropboxusercontent.com/scl/fi/vd6v8bhgq99c3t4fw8j4r/IMG_3772.JPG?rlkey=zebr4os1a4rwack8vogesc2l0&raw=1",
        "https://dl.dropboxusercontent.com/scl/fi/0u4m081c54ezbbovs36e8/IMG-7131.jpg?rlkey=neeocd4byi90nmti1g2opt8cj&raw=1",
        "https://dl.dropboxusercontent.com/scl/fi/ij4a186qh4qorfwn8oagj/IMG-6861.jpg?rlkey=gw2oc1zijfqqxc4feulzcfoqf&raw=1",
        "https://dl.dropboxusercontent.com/scl/fi/lmrpagqxhupgmuvd9yqoo/IMG_3947.JPG?rlkey=5xgif3g3mooi4m55o4x67n36g&raw=1",
        "https://dl.dropboxusercontent.com/scl/fi/kz43vky6xxt6nq4bzwirr/IMG_6790.jpg?rlkey=1x04va9nryzzry5y0g9fsgbpr&raw=1",
        "https://dl.dropboxusercontent.com/scl/fi/q5k02dh49tmq0hnmblpvj/IMG_4429.jpg?rlkey=p0cxz82auk4jrs1i37axcc0qp&raw=1"

        // Add more image paths here
    ];

    // Split images into two columns
    const column1Images = images.filter((_, index) => index % 2 === 0);
    const column2Images = images.filter((_, index) => index % 2 !== 0);

    return (
        <div className="photography">
            <div className="column">
                {column1Images.map((image, index) => (
                    <img key={index} src={image} alt={`Photography ${index}`} />
                ))}
            </div>
            <div className="column">
                {column2Images.map((image, index) => (
                    <img key={index} src={image} alt={`Photography ${index}`} />
                ))}
            </div>
        </div>
    );
}

export default Photography;
